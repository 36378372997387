<template>
  <div class="diy_mask" v-show="showDiyModal" v-if="showDiyModal">
    <div class="diy_modal">
      <div class="section">
        <div class="sec_name">{{ sonObj.name }}</div>
        <div class="sec_box">
          <span
            class="div_li"
            v-for="item in columns"
            :key="item.code"
            :value="item.code"
            :class="item.code === result.code ? 'checked' : ''"
            @click="handleChecked(item)"
            >{{ item.name }}</span
          >
        </div>
        <div
          v-if="fatherObj.type == 'packing' && result.code == 'LZ'"
          style="padding-left:15px ;,font-size: 12px;color: #0081EB;"
        >
          注：小票零担产品无法承运裸装
        </div>
      </div>
      <div class="footer">
        <span class="btn" @click="onConfirm">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VantFieldSelect',
  model: {
    prop: 'selectValue'
  },
  props: {
    fatherObj: { Array, required: true }
  },
  data() {
    return {
      showDiyModal: false,
      selectCode: '',
      sonObj: this.fatherObj,
      result: { code: '', name: '' },
      columns: []
    }
  },
  methods: {
    handleChecked(item) {
      this.result = { code: item.code, name: item.name }
    },
    onConfirm() {
      this.showDiyModal = !this.showDiyModal
      if (!this.result.code || !this.result.name) {
        this.$message
        return
      }
      let newObj = {
        [this.sonObj.type]: this.result.code,
        [this.sonObj.type + 'Name']: this.result.name
      }
      this.$emit('getMessage', newObj) //把value值传给父组件
    }
  },
  watch: {
    fatherObj: function(newVal) {
      this.showDiyModal = this.fatherObj.isShow
      this.columns = [...newVal.columns]
      this.sonObj = newVal
      if (newVal.columns.length && newVal.selectCode) {
        newVal.columns.length &&
          newVal.columns.some(item => {
            if (item.code === newVal.selectCode) {
              let aa = { code: item.code, name: item.name }
              this.result = { ...aa }
              return true
            }
          })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.diy_mask {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}
.diy_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: auto;
  padding: 20px 10px 40px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  border-radius: 10px;
}
.sec_name {
  margin-bottom: 30px;
  font-size: 30px;
  text-align: center;
  line-height: 30px;
}
.sec_box {
  margin: 0 20px;
}
.div_li {
  display: inline-block;
  width: 30.3%;
  text-align: center;
  margin: 0 1.5% 20px;
  padding: 10px 0;
  color: #999;
  line-height: 40px;
  border: 1px solid #999;
  border-radius: 18px;
  box-sizing: border-box;
  &.checked {
    border-color: #007ec5;
    color: #007ec5;
  }
}
.footer {
  text-align: center;
  .btn {
    display: inline-block;
    width: 50%;
    margin-top: 30px;
    line-height: 90px;
    font-size: 28px;
    border-radius: 90px;
    color: #007ec5;
    border: 2px solid #007ec5;
  }
}
</style>

<template>
  <div class="body">
    <van-popup v-model="visiable" position="bottom" class="mypop">
      <p class="borL">保价运输</p>
      <van-form @submit="onSubmit">
        <van-row :style="{ 'background-color': '#fff' }">
          <van-col span="16" class="col">
            <van-field
              v-model="newStepArr.declaredValue"
              name="declaredValue"
              label="请录入货值"
              class="ctx"
            />
          </van-col>
          <van-col span="12" class="col"></van-col>
        </van-row>
        <p class="borL">回单服务</p>
        <van-radio-group v-model="newStepArr.receiptType">
          <van-cell-group>
            <van-cell title="推荐" border>
              <template #right-icon>
                <van-radio
                  icon-size="16px"
                  name="ZL1605211"
                  class="marR20"
                  @click.native="optionClick('电子回单', 'receiptTypeName')"
                  >电子回单</van-radio
                >
                <van-radio
                  icon-size="16px"
                  name="ZL1605215"
                  @click.native="optionClick('无需回单', 'receiptTypeName')"
                  >无需回单</van-radio
                >
              </template>
            </van-cell>
            <van-cell title="付费">
              <template #right-icon>
                <van-radio
                  icon-size="16px"
                  name="ZL1605214"
                  class="marR20"
                  @click.native="optionClick('纸质回单 | 普通', 'receiptTypeName')"
                  >纸质回单 | 普通</van-radio
                >
                <van-radio
                  icon-size="16px"
                  name="ZL2209291"
                  @click.native="optionClick('纸质回单 | 快件', 'receiptTypeName')"
                  >纸质回单 | 快件</van-radio
                >
                <!-- <van-radio
                  icon-size="16px"
                  name="ZL1605216"
                  @click.native="optionClick('货物清单', 'receiptTypeName')"
                  >货物清单</van-radio
                > -->
              </template>
            </van-cell>
            <van-cell
              class="tipBox f999 "
              v-if="newStepArr.receiptTypeName == '纸质回单 | 普通' && isSvip"
            >
              <span class="tipTag">惠</span>回单费减免15元，注：小票零担产品不参与
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <!-- 回单方式为“纸质回单 | 快件”才展示 -->
        <template v-if="newStepArr.receiptType == 'ZL2209291'">
          <p class="borL">回单接受信息</p>
          <van-cell-group>
            <van-cell>
              <van-field
                label="接收人"
                v-model="newStepArr.receiptTypeUserName"
                name="receiptTypeUserName"
                class="h35"
                placeholder="请输入接收人"
              />
            </van-cell>
            <van-cell>
              <van-field
                label="接收电话"
                v-model="newStepArr.receiptTypeUserPhone"
                name="receiptTypeUserPhone"
                class="h35"
                placeholder="请输入接收电话"
                :rules="regExp('phone')"
              />
            </van-cell>
            <van-cell>
              <van-field
                label="接收地址"
                v-model="newStepArr.receiptTypeUserAddress"
                name="receiptTypeUserAddress"
                class="h35"
                placeholder="请输入接收地址"
              />
            </van-cell>
          </van-cell-group>
        </template>
        <p class="borL">包装服务</p>
        <van-cell class="packing">
          <template #title>
            <el-radio-group v-model="strAddedService" @change="handleChecked">
              <el-radio
                v-for="item in $store.state.addedServiceList"
                :label="item.id"
                :key="item.id"
                class="addedService"
              >
                {{ item.name }}
              </el-radio>
            </el-radio-group>
          </template>
          <template #label>
            <van-cell title="托盘数量" class="pad0" v-show="packingService">
              <template #default>
                <van-field
                  v-model="newStepArr.mtp"
                  name="num"
                  class="ctx tr"
                  placeholder="请输入托盘个数"
                  :rules="packingService ? regExp('integer') : []"
                />
              </template>
              <template #right-icon> 个 </template>
            </van-cell>
            <van-cell title="木箱厚度" class="pad0" v-if="isShowMxFun()">
              <el-radio-group v-model="mxType" @change="handleSelete" class="flex">
                <el-radio
                  v-for="item in $store.state.addedServiceMx"
                  :label="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </el-radio>
              </el-radio-group>
              <!-- <el-select v-model="mxType" class="myselect" @change="handleSelete">
                  <el-option
                    class="myselect"
                    v-for="item in $store.state.addedServiceMx"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select> -->
            </van-cell>
            <!--  <el-col :span="8" v-if="isShowMxFun()">
          <el-form-item class="mx_select">
            <span>木箱厚度：</span>
            <el-select v-model="mxType" class="myselect" :disabled="isLock" @change="handleSelete">
              <el-option
                class="myselect"
                v-for="item in $store.state.addedServiceMx"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
            <div v-html="tips" class="pad10"></div>
          </template>
        </van-cell>
        <p class="borL">送货服务</p>
        <van-cell class="packing">
          <template #title>
            <van-radio-group v-model="newStepArr.pickService" direction="horizontal">
              <van-radio name="shsm" icon-size="16px">送货上门</van-radio>
              <van-radio name="shjc" icon-size="16px">送货进仓</van-radio>
              <van-radio name="ziti" icon-size="16px">自提</van-radio>
            </van-radio-group>
          </template>
          <template #label>
            <div v-show="deliverSerive" class="pad10">
              送货进仓需要在送货费的基础上加收进仓费，货物送达仓库后若需另外交费的，平台提供付款凭证后由客户实报实销
            </div>
          </template>
        </van-cell>
        <p class="borL">装卸服务</p>
        <van-cell class="packing">
          <template #title>
            <van-checkbox-group v-model="newStepArr.services" direction="horizontal">
              <van-checkbox icon-size="15px" name="zh" shape="square">装货</van-checkbox>
              <van-checkbox icon-size="15px" name="xh" shape="square">卸货</van-checkbox>
            </van-checkbox-group>
          </template>
          <template #label>
            <div class="pad10">
              服务说明：单件货物重量>40kg，长度≥2米，平台将联系您单独议价；
            </div>
          </template>
        </van-cell>
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>
<script>
import { regExp } from '@/assets/js/global'
import { SHOUHUOARR } from '@/assets/js/orderLibrary'
import { getUserByMobile, receiptInfoFun } from '@/assets/js/order'
export default {
  name: 'serviceProfit',
  props: {
    isBatch: {
      type: Boolean
    }
  },
  data() {
    return {
      newStepArr: SHOUHUOARR, //所有表单数据
      visiable: false,
      regExp,
      mxType: '', //木箱类型
      strAddedService: '0' //单选的增值服
    }
  },
  created() {
    getUserByMobile(this) //获取客户标签和货值减免
  },
  computed: {
    tips() {
      if (this.newStepArr.addedService.includes('0')) {
        return '<span>不需要木架、木托盘木箱包装</span>'
      } else {
        if (
          this.newStepArr.addedService.includes('mj') ||
          this.newStepArr.addedService.includes('mtp')
        ) {
          if (
            this.newStepArr.addedService.includes('bkml') ||
            this.newStepArr.addedService.includes('hkml')
          ) {
            return '<span>木架、木托盘最终以实际使用为准</span><br/><span>木箱最终以实际测量体积为准</span>'
          } else {
            return '<span>木架、木托盘最终以实际使用为准</span>'
          }
        } else {
          if (
            this.newStepArr.addedService.includes('bkml') ||
            this.newStepArr.addedService.includes('hkml')
          ) {
            return '<span>木箱最终以实际测量体积为准</span>'
          } else {
            return null
          }
        }
      }
    },
    packingService() {
      return this.newStepArr.addedService.includes('mtp')
    },
    deliverSerive() {
      return this.newStepArr.pickService === 'shjc'
    },
    //客户是不是svip
    isSvip() {
      let isSvip = this.$store.state.isSvip
      return isSvip
    }
  },
  watch: {
    newStepArr(newVal, oldVal) {
      this.newStepArr = newVal
      this.addedServiceFun() //重组包装方式
    },
    'newStepArr.receiptType'(newVal) {
      //判断回单方式的选择,如果是纸质回单 | 快件，展示回单接受信息，并默认值为发货信息
      this.newStepArr = receiptInfoFun(this.newStepArr)
    }
  },
  methods: {
    show(datas) {
      this.newStepArr = datas ? datas : SHOUHUOARR
      this.visiable = true
    },
    onSubmit(values) {
      this.$emit('comfirm', this.newStepArr)
      this.visiable = false
    },
    optionClick(name, field) {
      if (this.newStepArr[field] === name) return
      this.newStepArr[field] = name
    },
    handleChecked(e) {
      this.strAddedService = e
      this.arrAddedServiceFun()
      // if (e.length) {
      //   if (e[e.length - 1] === '0') {
      //     if (e.join(',') == '0') return //防止死循环
      //     this.newStepArr.addedService = ['0']
      //   } else {
      //     if (e.includes('0')) {
      //       this.newStepArr.addedService = [e[e.length - 1]]
      //     }
      //   }
      // }
    },
    //格式化
    arrAddedServiceFun() {
      let str = this.strAddedService
      let mx = this.mxType
      let arr = []
      if (str == 'mjMtp') {
        arr = ['mj', 'mtp']
      } else if (str == 'mxMtp') {
        arr = ['mtp', mx]
      } else if (str == 'mx') {
        arr = [mx]
      } else {
        arr = [str]
      }
      this.newStepArr.addedService = arr
    },
    //包装方式服务回选处理
    addedServiceFun() {
      let addedServiceArr = this.newStepArr.addedService
      let hasMtp = addedServiceArr.includes('mtp') || ''
      let hasMj = addedServiceArr.includes('mj') || ''
      let hasMx = ['bkml', 'hkml'].filter(item => addedServiceArr.indexOf(item) > -1)
      let mxType = hasMx.length > 0 ? hasMx.join('') : ''
      if (hasMtp && hasMj) {
        this.strAddedService = 'mjMtp'
        this.mxType = mxType
      } else if (hasMtp && mxType) {
        this.strAddedService = 'mxMtp'
        this.mxType = mxType
      } else if (mxType) {
        this.strAddedService = 'mx'
        this.mxType = mxType
      } else {
        this.strAddedService = addedServiceArr.join()
      }
    },
    //展示木托盘数量输入框
    isShowMtpFun() {
      let str = this.strAddedService
      return str.includes('mtp') || str.includes('mjMtp') || str.includes('mxMtp') ? true : false
    },
    //展示木箱选择
    isShowMxFun() {
      let str = this.strAddedService
      return str.includes('mx') || str.includes('mxMtp') ? true : false
    },
    //选择木箱
    handleSelete(e) {
      this.mxType = e
      this.arrAddedServiceFun()
    }
  }
}
</script>
<style lang="less" scoped>
.body {
  padding: 20px;
  .mypop {
    height: 100%;
    background-color: #f5f5f5;
    padding: 0 20px;
    box-sizing: border-box;
  }
}
.col {
  .ctx ::v-deep > .van-cell__value .van-field__body {
    &:after {
      content: '元';
      font-size: px;
      color: #666;
    }
  }
}
.borL {
  border-left: 5px solid #f5ce98;
  padding-left: 10px;
  margin-top: 30px;
}
.packing {
  /deep/.van-cell__label {
    border-top: 1px solid #ebedf0;
  }
  .pad10 {
    padding-top: 15px;
  }
}
.marR20 {
  margin-right: 20px;
}
.pad0 {
  padding: 10px 0;
  .ctx {
    padding: 0;
  }
}
.hideIcon {
  margin-bottom: 10px;
  /deep/.van-radio__icon {
    display: none;
  }
  /deep/.van-radio__label {
    margin-left: 0;
  }
}
.addedService {
  display: inline-block;
  width: 33%;
  margin-right: 0;
  margin-bottom: 0.1rem;
}
.tipBox {
  padding: 20px 0;
}
.tipTag {
  display: inline-block;
  margin-left: 16px;
  margin-right: 0.2rem;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #ff6648;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
}
.f999 {
  font-size: 12px;
  color: #999;
  line-height: 30px;
}
.h35 {
  padding: 0px;
}
</style>

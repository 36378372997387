<template>
  <div class="place_order">
    <div class="mb30 nobg_box block_base">
      <van-steps :active="0" active-color="#007EC5">
        <van-step>货物信息</van-step>
        <van-step>选择产品</van-step>
        <van-step>信息确认</van-step>
      </van-steps>
    </div>
    <CargoLabelCmp
      v-if="this.$store.state.cargoLabelList.length"
      typeRef="orderM"
      :newStepArr="newStepArr"
      :sp="newStepArr.sp || ''"
      :isEdit="isEditNew"
      @changeEdit="changeEdit"
      @changeSp="changeSp"
    ></CargoLabelCmp>
    <van-form @submit="onSubmit" ref="oneForm">
      <van-row
        type="flex"
        justify="center"
        class="van-hairline--bottom block_base mt30"
        v-show="isEditNew"
      >
        <van-col span="24" class="mt30 indent8">合计</van-col>
        <van-col span="8" class="col">
          <van-field
            v-model="newStepArr.totalPakages"
            name="totalPakages"
            placeholder="包装件数"
            class="h35 borRight ctx1"
            :rules="regExp('integer')"
          />
        </van-col>
        <van-col span="8" class="col">
          <van-field
            v-model="newStepArr.goodsWeight"
            name="goodsWeight"
            placeholder="总重量"
            class="h35 borRight ctx2"
            :rules="regExp('oneDec')"
          />
        </van-col>
        <van-col span="8" class="col">
          <van-field
            v-model="newStepArr.goodsVolume"
            name="goodsVolume"
            class="h35 ctx3"
            placeholder="总体积"
            :rules="regExp('sixDec')"
          />
        </van-col>
      </van-row>
      <van-row type="flex" justify="center" class="van-hairline--bottom mt30 block_base">
        <van-col span="12" class="col">
          <van-cell
            title="货物类型"
            is-link
            arrow-direction="down"
            :value="newStepArr.cargoTypeName"
            @click.stop="
              () => showRadioModal('货物类型', 'cargoType', cargoTypeVO, newStepArr.cargoType)
            "
          />
        </van-col>
        <van-col span="12" class="col">
          <van-cell
            title="包装方式"
            is-link
            arrow-direction="down"
            :value="newStepArr.packingName"
            @click.stop="() => showRadioModal('包装方式', 'packing', packingVO, newStepArr.packing)"
          />
        </van-col>
      </van-row>
      <van-cell-group class="block_base">
        <van-cell title="增值服务" is-link @click="popUp">
          <template #default
            ><span class="f333">{{ service }}</span></template
          >
        </van-cell>
        <van-cell title="备注">
          <template #default>
            <van-field
              v-model="newStepArr.remark"
              name="remark"
              class="h35"
              placeholder="请填写托运备注"
            />
          </template>
        </van-cell>
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit">选择产品</van-button>
      </div>
    </van-form>
    <VantFieldSelect :fatherObj="fatherObj" @getMessage="getMessage" />
    <ServiceProfit ref="serviceRef" @comfirm="updataService"></ServiceProfit>
  </div>
</template>
<script>
import {
  getCargoLabelList,
  providerInfoAllN,
  getDetailStr,
  getZengZhiAll,
  getByCode,
  fetchPackingList,
  calculateWeight
} from '@/assets/js/order'
import { ORDERSTORE } from '@/assets/js/orderLibrary'
import { regExp, multiply, getFloat, priceT } from '@/assets/js/global'
import { getBatchOrderForm, setOrderForm } from '@/assets/js/storage'
import ServiceProfit from './serviceProfit.vue'
import VantFieldSelect from './VantFieldSelect'
import { getUserStore, setBatchOrderForm } from '@/assets/js/storage'
import CargoLabelCmp from '@/components/cargoLabelCmp/CargoLabelCmp.vue' //引入货物规格
const userData = getUserStore()
export default {
  name: 'stepOne',
  components: { ServiceProfit, VantFieldSelect, CargoLabelCmp },
  data() {
    return {
      allBatchDatas: {}, //所以批量数据
      isBatch: false, //不是批量下单
      faIdx: undefined, //批量下单的订单序号
      newStepArr: ORDERSTORE, //单条订单数据
      regExp: regExp,
      // service: "无需回单、送货、报价",
      cargoTypeVO: this.$store.state.cargoTypeVO, //货物类型
      tempList: [], // 标签值的临时变量
      isEdit: true, //true则为显示合计吨方件、false为显示规格明细
      fatherObj: {
        name: '',
        type: '',
        columns: [],
        selectCode: '',
        isShow: false
      }
    }
  },
  computed: {
    packingVO() {
      //包装方式列表
      return this.$store.state.byPCode.packingVO
    },
    service() {
      let str = getDetailStr(this.newStepArr, true)
      return str
    },
    isEditNew() {
      return this.isEdit
    }
  },
  created() {
    getByCode(this)
    let allDatas = this.$route.params
    if (allDatas.oneDatas) {
      this.allBatchDatas = allDatas.allBatchDatas
      this.newStepArr = allDatas.oneDatas
      this.isBatch = allDatas.isBatch || false
      this.faIdx = allDatas.faIdx
    } else {
      this.$router.push({
        path: '/m_batchOne'
      })
    }
    let byPCode = this.$store.state.byPCode
    if (!byPCode.packingVO.length) {
      getZengZhiAll()
      fetchPackingList()
    }
    if (userData.corpId) {
      getCargoLabelList(userData.corpId) //获取货物规格列表
    }
  },
  methods: {
    async changeSp(item) {
      this.newStepArr = {
        ...this.newStepArr,
        ...item
      }
    },
    showRadioModal(name, type, lists, selectCode) {
      this.fatherObj = {
        name,
        type,
        columns: lists,
        selectCode,
        isShow: true
      }
    },
    getMessage(arr) {
      this.newStepArr = {
        ...this.newStepArr,
        ...arr
      }
    },
    popUp() {
      setOrderForm(this.newStepArr)
      this.$refs.serviceRef.show(this.newStepArr)
    },
    changeIptVal(iptVal, line, cargoId) {
      let cargoDatas = [...this.tempList]
      let curLine = cargoDatas[line],
        t = 0,
        v = 0
      if (cargoId != '0') {
        //点击的tag为‘其它’时，不用自动计算
        t = getFloat(multiply(iptVal, priceT(curLine.unitWeight)), 4)
        v = getFloat(multiply(iptVal, curLine.unitVolume), 6)
        cargoDatas[line].weight = t > 100000 ? 100000 : t
        cargoDatas[line].vol = v > 10000 ? 10000 : v
      }
      cargoDatas[line].total = iptVal > 10000 ? 10000 : iptVal
      this.tempList = [...cargoDatas]
    },
    changeEdit(flag) {
      this.isEdit = flag || false
    },
    // 获取增值服务的信息对象
    updataService(datas) {
      this.newStepArr = {
        ...this.newStepArr,
        ...datas
      }
      setOrderForm(this.newStepArr)
    },
    getDateTime(dateTime) {
      const year = new Date().getFullYear()
      const nav = dateTime['nav'].text
      const day = nav.split('   ')[1]

      const mm = day.split('月')[0]
      const dd = day.split('月')[1].split('日')[0]
      const timeRange = dateTime['child'].text
      this.newStepArr.sendersDate = `${year}-${mm}-${dd}`
      this.newStepArr.sendersTime = `${timeRange}`
    },
    async onSubmit(values) {
      let weightAndVolume = await calculateWeight(this.newStepArr, this)
      let datas = { ...this.newStepArr, ...weightAndVolume }
      if (!datas.sendersName || !datas.sendersPhone || !datas.sendersCityName) {
        this.$toast('请选择收货信息')
        return
      }
      if (!datas.recipientName || !datas.recipientPhone || !datas.recipientCityName) {
        this.$toast('请选择收货信息')
        return
      }
      if (!datas.cargoTypeName) {
        this.$toast('请选择货物类型')
        return
      }
      if (!datas.packing) {
        this.$toast('请选择包装方式')
        return
      }
      if (!datas.sendersDate || !datas.sendersTime) {
        this.$toast('请选择预计发货时间')
        return
      }
      this.allBatchDatas.shList[this.faIdx] = {
        ...this.allBatchDatas.shList[this.faIdx],
        ...datas
      }
      setBatchOrderForm(this.allBatchDatas) //设置内存
      providerInfoAllN(this, datas, this.isBatch, 'mr', this.callbackProvider)
    },
    callbackProvider(isNext) {
      if (isNext !== true) return
      this.$router.push({
        name: 'lineGoods',
        params: {
          oneDatas: this.newStepArr,
          isBatch: this.isBatch || false,
          faIdx: this.faIdx
        }
      })
    },
    jumpToBatchForm() {
      this.$router.push({
        path: '/m_batchorder'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.padTp30 {
  padding-top: 30px;
}
.mt30 {
  margin-top: 30px;
}
.padlr24 {
  padding: 0 24px;
}
.marLt20 {
  margin-left: 20px;
}
.size26 {
  font-size: 26px;
  padding: 5px;
  line-height: 34px;
  border-radius: 5px;
}
.swiper_box ::v-deep .van-swipe-item {
  width: auto !important;
  margin-right: 10px !important;
}
.tag {
  padding: 10px 30px 10px 20px;
  border-radius: 12px;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  font-family: Alibaba PuHuiTi;
  &.alive {
    background-color: #1989fa;
    color: #fff;
  }
}
.borRight {
  border-right: 1px solid #dbdcdb;
}
.ctx1 ::v-deep .van-cell__value .van-field__body {
  &:after {
    content: '件';
    font-size: px;
    color: #666;
  }
}
.ctx2 ::v-deep > .van-cell__value .van-field__body {
  &:after {
    content: '公斤';
    width: 42px;
    display: inline-block;
    font-size: px;
    color: #666;
  }
}
.ctx3 ::v-deep > .van-cell__value .van-field__body {
  &:after {
    content: '方';
    font-size: px;
    color: #666;
  }
}
.h35 {
  padding: 5px 10px;
}
.bgW {
  background-color: #fff;
}
.borBt {
  border-bottom: 1px solid #dbdcdb;
}
.indent8 {
  text-indent: 16px;
  background-color: #fff;
  padding-top: 10px;
}
.col {
  padding: 15px 0;
  background-color: #fff;
}
.tips {
  width: 100%;
  color: #666;
  margin-top: 30px;
  span {
    color: #2a97e8;
  }
}
.reflex ::v-deep .van-cell__title {
  flex: revert;
}
</style>
